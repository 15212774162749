import { Injectable } from '@angular/core';
import Clarity from '@microsoft/clarity';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClarityService {

  constructor(
  ) {
  }

  disableTracking(): void {
    Clarity.consent(false);
  }


  init(): void {
    console.log('Initiating Clarity');
    Clarity.init(environment.clarityProjectId);
  }

  identify(userDetails: any): void {
    Clarity.identify(userDetails.email, 'ATTENDER_BUYER', 'BUYER_PORTAL', `${userDetails.companyName}(${userDetails.fullName})`);
  }
}
