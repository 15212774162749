// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-methods-container {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.payment-methods-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-method-item {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 16px;
  transition: all 0.3s;
}

.payment-method-item:hover {
  background-color: #fafafa;
}

.payment-method-label {
  display: flex;
  width: 100%;
}

.payment-card-info {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.card-brand {
  display: flex;
  align-items: center;
  gap: 8px;
}

.brand-icon {
  font-size: 20px;
}

.card-details {
  font-weight: 500;
}

.card-expiry {
  color: rgba(0, 0, 0, 0.45);
}

.add-card-button {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/common/shared-module/choose-payment-method/choose-payment-method.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACF","sourcesContent":[".payment-methods-container {\n  padding: 16px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.payment-methods-list {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.payment-method-item {\n  border: 1px solid #f0f0f0;\n  border-radius: 4px;\n  padding: 16px;\n  transition: all 0.3s;\n}\n\n.payment-method-item:hover {\n  background-color: #fafafa;\n}\n\n.payment-method-label {\n  display: flex;\n  width: 100%;\n}\n\n.payment-card-info {\n  margin-left: 8px;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.card-brand {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.brand-icon {\n  font-size: 20px;\n}\n\n.card-details {\n  font-weight: 500;\n}\n\n.card-expiry {\n  color: rgba(0, 0, 0, 0.45);\n}\n\n.add-card-button {\n  display: flex;\n  align-items: center;\n  margin-top: 12px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
