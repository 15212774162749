import { Component, inject, Input, OnInit } from '@angular/core';
import { RequesterService } from '../../services/requester.service';
import { AddCardComponent } from 'src/app/main/billing/add-card/add-card.component';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-choose-payment-method',
  templateUrl: './choose-payment-method.component.html',
  styleUrl: './choose-payment-method.component.scss'
})
export class ChoosePaymentMethodComponent implements OnInit {
  @Input() paymentMethods: any[] = [];
  @Input() stripeDefaultPaymentIdBuyer: any;
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  isLoading = false;
  isBillingManger = false;
  constructor(
    private requesterService: RequesterService,
    private nzModalService: NzModalService,
    private nzModalRef: NzModalRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if(this.nzModalData?.stripeDefaultPaymentIdBuyer) {
      this.stripeDefaultPaymentIdBuyer = this.nzModalData.stripeDefaultPaymentIdBuyer;
    }
    this.getMyPaymentMethods();

    this.authService.billingManagerState.subscribe((state) => {
      this.isBillingManger = state;
      console.log('Billing manager state:', state);
    });
  }

  getMyPaymentMethods() {
    this.isLoading = true;
    this.requesterService
      .request("get", "payments/stripe/buyer/payment-methods")
      .subscribe((res: any) => {
        this.paymentMethods = res.data;
        if (res.data.length === 0) {
          this.addCard();
        }
        this.isLoading = false;
      });
  }

   addCard() {
      const modal = this.nzModalService.create({
        nzTitle: "Add Card",
        nzContent: AddCardComponent,
        nzFooter: null,
        nzClosable: true,
        nzMaskClosable: false,
        nzBodyStyle: {
          padding: "0",
        },
        nzNoAnimation: true,
      });
      modal.afterClose.subscribe((res: any) => {
        this.getMyPaymentMethods();
      });
    }

    getCardIcon(brand: string): string {
      const icons = {
        visa: 'credit-card',
        mastercard: 'credit-card',
        // Add more card brands as needed
      };
      return icons[brand] || 'credit-card';
    }

    onCardChoose() {
      this.nzModalRef.close(this.stripeDefaultPaymentIdBuyer);
    }
}
