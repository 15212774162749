// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.attender.ai',
  mediaBaseUrl: 'https://api.attender.ai/uploads/',
  captchaSiteKey:'6LcgGMIpAAAAAMAKU8CII1Y9HLbrMPRhlRAC8OqV',
  marineAPIURL: 'https://services.marinetraffic.com/api/vesselmasterdata/v:3/2f602f35c37fff2ba76efb5273a632dbe53b139a/',
  sessionTimeOutInSeconds : 7200,
  googleLoginPublicKey : '20431365903-hr67dnv6a62fifdjnt5u5f6uabm0v4uc.apps.googleusercontent.com',
  streamChat: {
    apiKey: 'zqstrzyy75ve',
    appId: '1125142',
  },
  clarityProjectId: 'q1t5zoarin'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
